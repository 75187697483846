type AppendScriptParams = {
  id: string
  scriptToAppend: string
  isAsync?: boolean
  where?: "body" | "head"
  type?: string
}

export const appendScript = ({
  id,
  scriptToAppend = "",
  isAsync = true,
  where = "body",
  type = "script",
}: AppendScriptParams) => {
  const existingScript = document.getElementById(id)

  if (!existingScript) {
    const script = <HTMLScriptElement>document.createElement(type)
    script.id = id
    script.src = scriptToAppend
    script.async = isAsync
    document[where].appendChild(script)
  }
}
export const appendInlineScript = ({
  id,
  scriptToAppend,
  isAsync = true,
  where = "body",
  type = "script",
}: AppendScriptParams) => {
  const existingScript = document.getElementById(id)
  if (!existingScript) {
    const script = <HTMLScriptElement>document.createElement(type)
    script.id = id
    script.text = scriptToAppend.replace(/^\s\n+|\s\n+$/g, "")
    script.type = "text/javascript"
    script.async = isAsync
    document[where].appendChild(script)
  }
}
